<template>
  <div class="my-4 space-y-4">
    <img
      src="/assets/nami/stickers/no_search_results.png"
      class="w-48 h-auto mx-auto"
    />
    <div class="text-center">
      <div class="text-lg font-medium">
        {{ $t("components.theSearchNoResults.text1") }}
      </div>
      <div>{{ $t("components.theSearchNoResults.text2") }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp();
const $t = nuxtApp.$i18n.global.t;
</script>
